import React, { useEffect,useRef } from "react";
import { useAtom } from 'jotai';
import useState from 'react-usestateref';
import {Box,Typography,Accordion,Container,ListItemText,AccordionSummary,AccordionDetails,Grow, Chip, Stack} from '@mui/material';

import { ViewportList } from "react-viewport-list";
import AddCircleOutlinedIcon from '@mui/icons-material/AddCircleOutlined';
import SettingsIcon from '@mui/icons-material/Settings';
import SearchIcon from '@mui/icons-material/Search';
import Highlighter from "react-highlight-words";

const GroupedItemList = ({buildOut,props,relevance,divQuickStats})=>{
  const [UserMap] = useAtom(props.globalState.userMapState);
  const [searchState] = useAtom(props.globalState.searchState);
  const ref = useRef(null);
  const ref2 = useRef(null);
  const [, setStyle, {current:styleRef}] = useState({});
  const [, setExpanded, {current:expandRef}] = useState([]);
  const handleExpansion = (DivisionID) => setExpanded(p=> p.includes(DivisionID) ? p.filter(x=>x!==DivisionID) : [].concat(p,DivisionID));

  useEffect(()=>{
   if(buildOut) setExpanded(buildOut?.flatMap(({DivisionID})=>DivisionID));
  },[buildOut])

  useEffect(()=>{
    if(UserMap.get('projectsView')==='jsListView') setStyle({display:'flex',flexDirection:'column', gap:'5px', padding:'.5%',willChange:'transform'})
    else setStyle({display:'flex',flexWrap:'wrap',gap:'5px',rowGap: '10px', padding:'.5%',justifyContent:'space-evenly',willChange:'transform'})
  },[ UserMap.get('projectsView')])

  return <Box ref={ref} sx={{display:'flex',flexDirection:'column',gap:'3px',willChange:'transform'}}>

  <ViewportList viewportRef={ref} items={buildOut}>
    {({DivisionID,Projects},i) => 
     Projects.length>0 &&<Accordion
     disableGutters
     key={DivisionID}
     expanded={expandRef.includes(DivisionID)}
     onChange={()=>handleExpansion(DivisionID)}
     slots={{ transition: Grow }}
     slotProps={{ transition: { timeout: 400, unmountOnExit: true } }}
     sx={{ 
       '& .MuiAccordion-region': { height: expandRef.includes(DivisionID) ? 'auto' : 0 },
       '& .MuiAccordionDetails-root': { display: expandRef.includes(DivisionID) ? 'block' : 'none', padding:0 },
     }}
   >
     <AccordionSummary aria-controls="panel1-content" sx={{position:'sticky',top:0,left:0,zIndex:99,padding:0, '& .MuiAccordionSummary-content': {margin:'0!important'}}}>
      <DivsHeader DivisionID={DivisionID} divQuickStats={divQuickStats?.[DivisionID+'-quickStats']} {...props} Projects={Projects.flatMap(({_id})=>_id)} />
     </AccordionSummary>
     <AccordionDetails>
     <Box ref={ref2} sx={styleRef}>
       <ViewportList
       viewportRef={ref}
       items={Projects.sort((a, b) => 
         new Date(a?.ProjectState?.Calendar?.endDate || a?.SalesState?.projectDue) - new Date(b?.ProjectState?.Calendar?.endDat || b?.SalesState?.projectDue)
        )}
       axis={UserMap.get('projectsView')==='jsListView'?'y':'x'}
     >
       {({_id,panel}, i) => <Box key={_id}
         slots={{ transition: Grow }}
         slotProps={{ transition: { timeout: 400, unmountOnExit: true } }} 
         sx={{display:'flex',flexDirection:'column',gap:'5px',margin: UserMap.get('projectsView')==='jsListView'?'unset':'auto'}}
         >
          { panel }
          { searchState.has("searchResult") && relevance[_id+'relevance'] && <Typography sx={{lineHeight:1}} variant="caption">
            <SearchIcon sx={{padding:'0 1%',fontSize:'9px'}}/>
            <Highlighter
                searchWords={[searchState.get("search")]}
                autoEscape={true}
                textToHighlight={relevance[_id+'relevance'].join(": ")}
              /></Typography> }
         </Box>
       }
     </ViewportList>
      </Box>
      </AccordionDetails>
      </Accordion> || <Typography></Typography>
      }
  </ViewportList>
  </Box>
};


const ListGrid = (props) => {
  const {globalState,dynamicComponent,sharedworker} = props;
  const [projectMap] = useAtom(globalState.projectMapState);
  const [projectFilterMap] = useAtom(globalState.projectFilterMapState);
  const [divisionFilterMap] = useAtom(globalState.divisionFilterMapState);
  const [divisionMap] = useAtom(globalState.divisionMapState);
  const [UserMap] = useAtom(globalState.userMapState);
  const [searchState] = useAtom(globalState.searchState);
  const mainState = useState(Object);
  const [, setState, {current:stateRef}] = mainState;
  const [, setRelevance, {current:relevanceRef}] = useState(Object);
  const [, , {current:statsRef}] = useState(Object);

  useEffect(()=>{
    sharedworker.postMessage({type:'filterProjects'})
  },[])

  useEffect(()=>{ 
    if(searchState.has("searchResult")) var Searched = searchState.get("searchResult")?.flatMap(({data})=>data)
   if(Searched){
    setRelevance({});
    Searched.flatMap(({_id,relevance})=> setRelevance(p=>({...p, [_id+'relevance']:relevance })) )
   }else setRelevance({});

   // Look here for bug, project not showing up becuase problem with filter here ->>>>>>>>>>>>>
   setState(p=>({...p, buildOut: globalState
    .groupByKey([...Array.from([...projectMap],([k,{DivisionID}])=>({DivisionID,_id:k}))],'DivisionID')
    .filter(({DivisionID})=>divisionFilterMap.has(DivisionID) && divisionFilterMap.get(DivisionID))
    .flatMap(({DivisionID,data})=>({DivisionID,Projects: 
      data
      .filter(({_id})=>projectFilterMap.has(_id) && projectFilterMap.get(_id))
      .filter(({_id})=> Searched ? Searched.flatMap(({_id})=>_id).includes(_id) : true )
      .filter(({_id})=>userSpecific({ProjectID:_id,DivisionID}))
      .flatMap(({_id},i)=>({_id,panel:dynamicComponent('panelBuild','component',{projID:{DivisionID,ProjectID:_id,modelType:divisionMap.get(DivisionID)?.teamName},i},mainState)}))}))
     }));
  },[projectMap,projectFilterMap,divisionFilterMap,searchState.get("searchResult")])

  const userSpecific = ({DivisionID,ProjectID}) =>{
   if(projectMap.has(ProjectID)) var {ProjectState,SalesState} = projectMap.get(ProjectID)
      if(UserMap.get('level')<3) return true
      if(UserMap.get('level')===5 && UserMap.get('division')===DivisionID) return true
      if(UserMap.get('avatar')=== ProjectState?.projManager || UserMap.get('avatar')===ProjectState?.teamLead) return true
      if(UserMap.get('level')===7 && ProjectState?.Paused?.some(x=> !x.endDate && x.startDate)) return false
      if(SalesState?.partnerID?.includes(UserMap.get('avatar')) || SalesState?.clientID?.includes(UserMap.get('avatar'))) return true
      if(ProjectState?.Members?.some(({id})=>id===UserMap.get('avatar'))) return ProjectState?.Members?.some(({id})=>id===UserMap.get('avatar'))
      return false
    }

  return <GroupedItemList {...stateRef} relevance={relevanceRef} props={props} divQuickStats={statsRef}/>
}

const DivsHeader=(props)=>{
  const {DivisionID,globalState,dynamicComponent,Projects,divQuickStats} = props
  const [divisionMap] = useAtom(globalState.divisionMapState);
  const mainState = useState(Object);
  const [, setState, {current:stateRef}] = mainState;

  useEffect(()=>{
    if(divisionMap.has(DivisionID)){
      setState(p=>({...p,...divisionMap.get(DivisionID),
        divisionIcon: dynamicComponent('AvatarBubble','elements',{state:'projLead',label:'missing Lead',isProject: true, userID:DivisionID,Catagory:'Division'},mainState) 
      }))
    }
  },[DivisionID,divisionMap.get(DivisionID)]);

 return <Box sx={{position:'sticky',top:0,zIndex:900,backgroundColor:'#32a7e2', padding:'.35%',color:'rgba(0, 0, 0, 0.6)',borderRadius:'8px',width:'100%'}}>
        <Container maxWidth="xl" sx={{display:'flex',gap:'3%',p:'0!important'}}>
         <Box sx={{display:'flex',gap:'2%',flex:1}}>
          {stateRef.divisionIcon}
        <ListItemText sx={{display:'flex',flexDirection:'column',justifyContent:'center'}} 
                primary={<Typography variant="body2" sx={{ overflow: 'hidden', textOverflow: 'ellipsis', display: '-webkit-box',WebkitLineClamp: '2', WebkitBoxOrient: 'vertical',lineHeight:1}}>{stateRef.divisionLabel||stateRef.teamName} </Typography>} 
                secondary={divQuickStats?<Box sx={{display:'flex',gap:'5px'}}>{divQuickStats}</Box> :<Typography variant="caption" color={'#fff'} sx={{ lineHeight:1, fontSize:'11px', overflow: 'hidden', textOverflow: 'ellipsis', lineHeight:1, display: '-webkit-box',WebkitLineClamp: '2', WebkitBoxOrient: 'vertical'}} >
                           Quick Stats Not Available
                          </Typography>
                }/>
        </Box>
        <Stack direction="row" spacing={1}>
        <Chip label={Projects?.length+` Project${Projects?.length>1?'s':''}`} color="warning" size="small" sx={{margin:'auto!important'}} />
        <DivisionOpt {...props} DivisionID={DivisionID}/>
        </Stack>
        </Container>
      </Box>
};

const DivisionOpt=(props)=>{
  const {DivisionID,dynamicComponent,globalState,That} = props;
  const [, setGlobalAppState] = useAtom(globalState.appState);
  const [UserMap] = useAtom(globalState.userMapState);
  const [divisionMap] = useAtom(globalState.divisionMapState);
  const mainState = useState(Object);
  const [, setState, {current:stateRef}] = mainState;

  useEffect(()=>{
    var userLvl = UserMap.get('level') 
    var list = [{primary:'Add Project',icon:<AddCircleOutlinedIcon/>}]
    if([0,1,2,3,4].includes(userLvl)) list.push({primary:'Settings',icon:<SettingsIcon/>})
    if(userLvl<=5) setState(p=>({...p,
      menuDrp: <Box key={'exampleKey'} sx={{display:'flex'}}>{dynamicComponent('DropDown','elements',{state:'optionsHeader', list:list, replace:true,buttonStyles:{padding:'unset'} },That,[(x)=>{
      var obj ={
      'Add Project': ()=>setGlobalAppState(p=>{ p.set('modulePop', dynamicComponent('editProj','component',{projID:{DivisionID,modelType:divisionMap.get(DivisionID).teamName},ref:module},That)); return new Map(p)}),
      'Settings': ()=>setGlobalAppState(p=>{ p.set('modulePop', dynamicComponent('editDivsn','component',{type:'admin',ref:module,DivisionID}, That)); return new Map(p)}),
      }
      obj[x.optionsHeader]()
  }])}</Box>
  }))
  },[UserMap.get('level') ])

    return stateRef.menuDrp
  };

export default ListGrid